<template>
  <div>
    <v-card v-for="(item, index) in items" :key="`${index}_${item.id}`">
      <v-card-title
        class="fs-14"
        :class="{ 'primary white--text': detailItem.id === item.id }"
        @click="showDetail(item)"
      >
        {{ formatDateTime(item.start_date, "DD/MM/YYYY") }} -
        {{ formatDateTime(item.end_date, "DD/MM/YYYY") }}:
        {{ item.warehouse_code }} - {{ item.tracking_id }}
      </v-card-title>

      <v-card-text v-if="detailItem.id === item.id">
        <v-simple-table class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">Người tạo</th>
                <th role="columnheader" class="text-center">Nội dung</th>
                <th role="columnheader" class="text-center">Kiểm kê</th>
                <th role="columnheader" class="text-center">Khách hàng</th>
                <th role="columnheader" class="text-center">Phương pháp</th>
                <th role="columnheader" class="text-center">SKU</th>
                <th role="columnheader" class="text-center">Số lượng</th>
                <th role="columnheader" class="text-center">Vị trí</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center">
                <td>{{ item.identity_name }}</td>
                <td>{{ item.name }}</td>
                <td>{{ $t(`labels.inventory_classify_${item.classify}`) }}</td>
                <td>{{ item.company_name }}</td>
                <td>{{ $t(`labels.inventory_type_${item.type}`) }}</td>
                <td>{{ item.inventory_sku }}/{{ item.request_sku }}</td>
                <td>{{ item.inventory_item }}/{{ item.request_item }}</td>
                <td>
                  {{ item.inventory_location }}/{{ item.request_location }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-3">
          <v-btn color="success" block @click="inventoryJoin(item)">{{
            $t("labels.join")
          }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";

export default {
  name: "Waiting",
  data: () => ({
    isLoading: false,
    page: 1,
    totalPage: 1,
    filters: {},
    items: [],
    detailItem: {},
  }),
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    formatNumber,
    showDetail(item) {
      this.detailItem = { ...item };
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getList: debounce(function () {
      httpClient.post("/inventory/v1/employee-list").then(({ data }) => {
        this.items = [...data];
      });
    }, 500),
    async inventoryJoin(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/inventory/v1/employee-join", item);
        this.isLoading = false;
        this.$emit("onJoined", item);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
